<template>
  <div class="page">
    <Navbar title="单位明细" type="SMP" :callback="back" />
    <van-swipe class="top-img" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        ><img
          :src="require('../../../../assets/images/recruit/consult.jpg')"
          class="img"
      /></van-swipe-item>
    </van-swipe>
    <div class="top">
      <van-row class="item bbs">
        <van-col span="6" class="left"
          ><van-tag mark :color="COLOR_M">单位名称</van-tag></van-col
        >
        <van-col span="16" class="right">{{ inst.name }}</van-col>
      </van-row>
      <van-row class="item bbs">
        <van-col span="6" class="left"
          ><van-tag mark :color="COLOR_M">信用代码</van-tag></van-col
        >
        <van-col span="16" class="right">{{ code }}</van-col>
      </van-row>
      <van-row class="item">
        <van-col span="6" class="left"
          ><van-tag mark :color="COLOR_M">注册地址 </van-tag></van-col
        >
        <van-col span="16" class="right">{{ inst.registerAddress }}</van-col>
      </van-row>
    </div>
    <van-tabs :active="activeType" :color="COLOR_M" border="true">
      <van-tab name="JOB" title="招聘岗位" class="job">
        <van-row class="item" v-for="job in jobList" :key="job.code">
          <van-col :span="18" class="text-short name">· {{ job.name }}</van-col>
          <van-col :span="6"
            ><van-button
              type="primary"
              size="mini"
              icon="sign"
              v-if="job.resever === 'N'"
              @click="createResever(job.code, job.name, job.type)"
              :color="COLOR_M"
              >预订岗位</van-button
            ><van-button
              type="primary"
              size="mini"
              icon="sign"
              v-if="job.resever === 'Y'"
              :color="COLOR_S1"
              >已预订</van-button
            ></van-col
          >
        </van-row>
        <van-image
          width="103"
          height="103"
          class="noRecord"
          v-if="jobList.length <= 0"
          :src="require('../../../../assets/images/home/no-record.png')"
        ></van-image>
      </van-tab>
      <van-tab name="RCT" title="补岗招聘" class="recruit">
        <van-row
          v-for="recruit in recruitList"
          :key="recruit.taskCode"
          class="item"
          @click="recruitDetail(recruit.taskCode)"
        >
          <van-col :span="24" class="name">· {{ recruit.name }}</van-col>
        </van-row>
        <van-image
          width="103"
          height="103"
          class="noRecord"
          v-if="recruitList.length <= 0"
          :src="require('../../../../assets/images/home/no-record.png')"
        ></van-image>
      </van-tab>
      <van-tab name="IFM" title="公招公告" class="inform">
        <van-row
          class="item"
          v-for="inform in informList"
          :key="inform.taskCode"
          @click="informDetail(inform.taskCode)"
        >
          <van-col :span="24" class="text-short name"
            >· {{ inform.name }}</van-col
          >
        </van-row>
        <van-image
          width="103"
          height="103"
          class="noRecord"
          v-if="informList.length <= 0"
          :src="require('../../../../assets/images/home/no-record.png')"
        ></van-image>
      </van-tab>
    </van-tabs>
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <Tabbar active="home" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
