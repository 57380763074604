import { Tabs, Tab, Tag, Image, Swipe, SwipeItem } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Login from '../../intercept/Login.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Navbar: Navbar,
        Login: Login,
        Share: Share,
        Loading: Loading,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Tag.name]: Tag,
        [Image.name]: Image,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem
    },
    data () {
        return {
            platform: '',
            code: '',
            inst: {},
            recruitList: [],
            informList: [],
            jobList: [],
            applyCount: 0,
            loadingShow: false,
            activeType: 'RCT'
        }
    },
    mounted () {
        // this.$refs.login.validate()
        var query = this.$route.query
        this.code = query.code
        if (query.type !== undefined && query.type !== '') {
            this.activeType = query.type
        }
        this.retrieveInst()
        this.retrieveInformList()
        this.retrieveRecruitList()
        this.retrievePaidCount()
    },
    methods: {
        init () {
            this.platform = window.sessionStorage.getItem(this.SESSION_PLATFORM)
            var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
            if (token !== undefined && token !== '' && token !== null) {
                this.initShare()
            }
        },
        async retrieveInst () {
            var pd = { code: this.code }
            const { data: res } = await this.$http.post(this.BMS_URL + '/data/institution/retrieveInstitution', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.inst = res.data
                this.retrieveJobList()
            }
        },
        async retrieveRecruitList () {
            var pd = { instCode: this.code, current: 1, size: 100 }
            const { data: res } = await this.$http.post(this.BMS_URL + '/recruit/taskAPC/retrieveInstTaskList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.recruitList = res.data
            }
        },
        async retrieveInformList () {
            var pd = { instCode: this.code, current: 1, size: 100 }
            const { data: res } = await this.$http.post(this.BMS_URL + '/inform/taskAPC/retrieveInstTaskList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.informList = res.data
            }
        },
        async retrieveJobList () {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { userCode: user, instCode: this.code, current: 1, size: 100 }
            const { data: res } = await this.$http.post(this.BMS_URL + '/data/institution/retrieveAPCInstJobPage', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.jobList = res.data
            }
        },
        async retrieveStdJobList () {
            var pd = { groupCodes: this.inst.jobGroup, current: 1, size: 100 }
            const { data: res } = await this.$http.post(this.BMS_URL + '/data/job/retrieveGroupsJobPage', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.stdJobList = res.data
            }
        },
        informDetail (code) {
            var key = window.const.global.INFORM_DETAIL_INDEX_BACK
            window.sessionStorage.setItem(key, window.location.href)
            this.$router.push({ path: '/mde/inform/detail', query: { code: code } })
        },
        recruitDetail (code) {
            var key = window.const.global.RECRUIT_CONSULT_INDEX_BACK
            window.sessionStorage.setItem(key, window.location.href)
            this.$router.push({ path: '/mde/recruit/consult', query: { taskCode: code } })
        },
        async retrievePaidCount () {
            var userCode = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { userCode: userCode, appKey: 'WPP_MOC', paidKeys: 'WORK_APPLY' }
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/paid/retrieveUserCusPaidList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.applyCount = res.data[0].surplusCount
            }
        },
        async createResever (jobCode, jobName, type) {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { serialCode: type + '-' + jobCode, userCode: user, type: 'JOB' }
            const { data: res } = await this.$http.post(this.BMS_URL + '/overall/resever/validateResever', this.$qs.stringify(pd))
            if (res.status === '200') {
                var exist = res.data.exist
                if (exist === 'N') {
                    if (this.applyCount > 0) {
                        this.$dialog.confirm({
                            title: '提示信息',
                            message:
                                '总次数：' + this.applyCount + ',提交预留将扣除1次申请次数，是否继续?'
                        })
                            .then(() => {
                                this.consumePaid('WORK_APPLY', 1)
                                this.executeCreateResever(user, jobCode, jobName, type)
                            })
                    } else {
                        this.$dialog.confirm({
                            title: '提示信息',
                            message:
                                '查看次数不足，是否立即充值?'
                        })
                            .then(() => {
                                this.buyPaid()
                            })
                    }
                } else {
                    this.$dialog.alert({
                        title: '提示信息',
                        message: '已提交预留申请，请勿重复提交'
                    })
                }
            }
        },
        async executeCreateResever (userCode, jobCode, jobName, type) {
            var param = { jobCode: jobCode, text: jobName, jobType: type, instCode: this.code }
            var pd = {
                userCode: userCode,
                serialCode: type + '-' + jobCode,
                type: 'JOB',
                param: JSON.stringify(param)
            }
            const { data: res } = await this.$http.post(this.BMS_URL + '/overall/resever/createResever', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '提交岗位预留成功'
                })
                this.retrieveJobList()
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '报名保存失败'
                })
            }
            this.loadingShow = false
        },
        async consumePaid (key, count) {
            var userCode = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { appKey: 'WPP_MOC', paidKey: key, userCode: userCode, count: count }
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/paid/consumePaid', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.retrievePaidCount()
            }
        },
        buyPaid () {
            this.$router.push({ path: '/work/paid' })
        },
        initShare () {
            var title = '好工作不求人，就上微职客'
            var desc = '国企、央企和事业单位等多种类型的多个职位任您选择'
            var link = 'https://moc.utopanet.com/recruit/home?f=1'
            var logo = 'https://moc.utopanet.com/static/logo.png'
            this.$refs.share.initParam(title, desc, link, logo)
        },
        back () {
            this.$router.push({ path: '/work/helper/home' })
        }
    }
}
